import { Box, CssBaseline, Skeleton, ThemeProvider } from "@mui/material";
import React, { useCallback, useState } from "react";
import PassbookCard from "../../layouts/PassbookCard";
import theme from "../../theme/theme";
import { Container } from "@mui/system";
import axiosService from "../../axios-api-kit/axiosService";
import { localStorageKey } from "../../constants/LocalStorageKey";
import { appTheme } from "../../theme/colorTheme";
import { useLocation, useNavigate } from "react-router-dom";

const Passbook = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [transactionsData, setTransactionsData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(null);
  const [totalNumberOfData, setTotalNumberOfData] = useState(null);

  const getTransactionData = useCallback(() => {
    axiosService.getTransactionDetails(pageNumber).then((response) => {
      setPerPage(response?.data?.per_page);
      setTotalNumberOfData(response?.data?.total);
      setTransactionsData((prvState) => !!prvState ? [...prvState, ...response?.data?.data]: response?.data?.data);
    }).catch(error => {
    })
  },[pageNumber])
  React.useEffect(() => {
    if (location.key === 'default') {
      navigate('/');
    } else {
      sessionStorage.removeItem(localStorageKey.SETIDSTABSTATE);
      getTransactionData();
    }

  }, [location.key, pageNumber])
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container>
          {transactionsData !== null ? <PassbookCard transactionsData={transactionsData} setPageNumber={setPageNumber} perPage={perPage} 
          totalNumberOfData={totalNumberOfData}/> : 
          <Box sx={{ width: '100%' }}>
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
        </Box>
          }
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};


export default Passbook;
