import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Slide from "@mui/material/Slide";
import { Box, Container, CssBaseline, DialogContent, Skeleton, ThemeProvider } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationsAccordian from "../../layouts/Notifications";
import axiosService from "../../axios-api-kit/axiosService";
import theme from "../../theme/theme";
import { appTheme } from "../../theme/colorTheme";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullNotificationsDialogScreen = () => {
    const [notificationsData, setNotificationsData] = React.useState(null);
    let navigate = useNavigate();
    const location = useLocation();
    const [openDialog, setOpenDialog] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [perPage, setPerPage] = useState(null);
  const [totalNumberOfData, setTotalNumberOfData] = useState(null);
    const handleDialogClose = () => {
        if(location.key === 'default'){
            navigate('/');
        }else{
            navigate(-1);
        }
        setOpenDialog(false);
    };

    const getNotificationsData = useCallback(() => {
        axiosService.getNotifications(pageNumber).then((response) => {
            setPerPage(response?.data?.per_page);
            setTotalNumberOfData(response?.data?.total);
            setNotificationsData((prvState) => !!prvState ? [...prvState, ...response?.data?.data] : response?.data?.data);
        }).catch(error => {
        })
    }, [pageNumber])
    
    
    const viewTransactionDetails = React.useCallback((selectedId) => {
        navigate(`/transactionsDetails?id=${selectedId}`)
      },[navigate])

    useEffect(() => {
        if (location.key === 'default') {
            navigate('/');
          } else {
            getNotificationsData();
          }
    }, [location.key, pageNumber])
    return (
        <>
            <Dialog
                fullScreen
                open={openDialog}
                sx={{ maxWidth: "600px", margin: '0 auto' }}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative", bgcolor: appTheme.color0 }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleDialogClose()}
                            aria-label="close"
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="body1" component="div">
                            Notifications
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ backgroundColor: appTheme.color0, padding: 0, pb: 1 }}>
                <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Container sx={{
                            color: appTheme.color1,
                            mt: 2
                        }}>
                            {notificationsData !== null ? <NotificationsAccordian notificationsData={notificationsData} viewTransactionDetails={viewTransactionDetails} 
                            perPage={perPage}
                            totalNumberOfData={totalNumberOfData}
                            setPageNumber={setPageNumber} /> :
                                <Box sx={{ width: '100%' }}>
                                    <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                                    <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                                    <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                                    <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                                    <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                                    <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                                    <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                                    <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                                </Box>}

                        </Container>
                    </ThemeProvider>

                </DialogContent>
            </Dialog>
        </>
    );
};
const NotificationsDailog = React.memo(FullNotificationsDialogScreen);
export default NotificationsDailog;
